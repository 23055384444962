@use '../../abstracts/mixins' as *;

.content-marketing {
  --standard-margin: clamp(2.188rem, 1.847rem + 1.7vw, 3.125rem);

  border: 2px solid var(--foreground-color);
  margin-block-end: var(--standard-margin);
  background-color: var(--color-background);

  &:not(.illustrated) {
    border-top-width: 9px;
  }

  .form-copy-container {
    display: flex;
    flex-direction: column;
    padding-inline: var(--base);
    color: var(--steady);
    margin-block-start: 0;
    padding-block-start: var(--base);

    h3 {
      font-family: var(--body-bold);
      font-size: var(--heading-s);
      margin-block-start: var(--standard-margin);
    }

    .content-marketing__copy {
      p {
        margin: var(--base) 0;
        line-height: 1.3;
      }
    }

    form {
      margin-block-end: var(--standard-margin);
      display: flex;
      flex-direction: column;
      gap: var(--standard-margin);
      position: relative;

      @include mq(small) {
        flex-direction: row;
        gap: var(--base);
        flex-wrap: wrap;
      }

      .input {
        flex-grow: 1;
      }

      input:not([type='submit']),
      button[role='checkbox'] {
        border: 2px solid var(--steady);
        background-color: transparent;
        max-height: 3em;
      }

      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active,
      input:-internal-autofill-selected {
        background-color: var(--color-background) !important;
      }

      label {
        color: var(--steady);

        &:not(.checkbox__label) {
          padding-inline-start: 0;
        }
      }

      .checkbox {
        width: 100%;
      }

      button.form__submit {
        padding-inline: var(--standard-margin);
        padding-block: calc(var(--base) / 2);
        background-color: var(--steady);
        color: white;
        border-radius: 0;
        line-height: 1;
        font-family: var(--aside);
        font-weight: normal;
        margin-inline-start: auto;
        margin-inline-end: 0;
        max-height: 3em;
      }

      .message {
        --signal-color: var(--bg-color);
        padding: var(--base) !important;
        background-color: var(--signal-color) !important;
        font-family: var(--aside-bold);
        position: absolute;
        bottom: 3rem;
        line-height: 1.2;
        border-radius: 0.5rem;

        @include mq(small) {
          max-width: 55%;
          bottom: 0;
        }

        &--error {
          --signal-color: red;
        }

        &--success {
          --signal-color: var(--green);
        }
      }
    }
  }

  &.illustrated {
    @include mq(small) {
      @include grid-12;
      align-items: center;
      gap: 0;

      .form-copy-container {
        grid-area: 1 / 5 / 2 / 13;
      }

      .content-marketing-image__container {
        grid-area: 1 / 1 / 2 / 5;
        height: 100%;
        width: 100%;
        position: relative;
        display: grid;
        place-content: center;
        background-color: white;

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          right: 0;
          left: 100%;
          width: 0.1em;
          height: 100%;
          background-color: var(--steady);
        }
      }
    }
  }
}
