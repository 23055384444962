@use '../abstracts' as *;

:root {
  --section-spacing: 3.5rem;

  @include mq(medium) {
    --section-spacing: 7rem;
  }

  @each $color, $shade in $colors {
    @each $prop, $value in $shade {
      --clr-#{$color}-#{$prop}: #{$value};
    }
  }

  @each $screen-size, $property in $type-scale {
    @if $screen-size==small {
      @each $prop, $value in $property {
        --fs-#{$prop}: #{$value};
      }
    } @else {
      @include mq(small) {
        @each $prop, $value in $property {
          --fs-#{$prop}: #{$value};
        }
      }
    }
  }

  color-scheme: light dark;

  /***Grid Variables***/
  --grid-column: 91px;
  --grid-gutter: 20px;

  /***Base Values***/
  --scale-end: 1440px; // Maximalbreite des Contents
  --screen-padding: 20px; // Abstand zum Bildschirmrand in Größen < 1440px

  /***grid-area variables***/
  --2-11: 1 / 2 / 2 / 12; // 1 column margin centered
  --two-thirds-left: 1 / 1 / 2 / 9;
  --two-thirds-right: 1 / 5 / 2 / 13;
  --5-7: 1 / 1 / 2 / 6;
  --7-5: 1 / 8 / 2 / 13;
  --last-third: 1 / 9 / 2 / 13;

  /***Fonts***/
  --body: 'PT Serif';
  --body-bold: 'PT Serif Bold';
  --heading: 'Abril Fatface';
  --aside: 'Neue DIN Semi-Wide';
  --aside-bold: 'Neue DIN Semi-Wide Bold';
  --ci: 'Neue DIN Semi-Wide Bold';
  --list: 'PT Serif Italic';
  --body-italic: 'PT Serif Italic';
  --pagetitle: 'Neue Din Semi-Wide Heading';

  /***Fluid Type Scale***/

  --base: clamp(1.125rem, 1rem + 0.63vw, 1.563rem); //18 - 25 px
  --small: clamp(0.875rem, 0.768rem + 0.54vw, 1.25rem); // 14 - 20
  --heading-m: clamp(1.875rem, 1.696rem + 0.89vw, 2.5rem); //30 - 40
  --heading-s: clamp(1.625rem, 1.464rem + 0.8vw, 2.188rem); // 26 - 35
  --heading-l: clamp(1.875rem, 1.339rem + 2.68vw, 3.75rem); //30 - 60
  --content-xl: clamp(2.5rem, 1.786rem + 3.57vw, 5rem); // 40 - 80
  --heading-xl: clamp(3.125rem, 2.589rem + 2.68vw, 5rem); // 50 - 80
  --heading-srp: clamp(1.25rem, 1.03rem + 0.94vw, 1.875rem); // 20 - 30
  --highlight-quote: clamp(2rem, 1.591rem + 2.05vw, 3.125rem); // 32 - 50

  --search-form-height: clamp(3.75rem, 3.53rem + 0.94vw, 4.375rem); // 60 - 70

  /***ANIMATIONS***/

  /***COLORS***/

  --foreground-color: #252525;
  --background-color: white;
  --dm-backdrop: #222;
  --text: #252525;
  --steady: #252525;
  --link-color: #1e46f3;
  --grey: #dddddd;
  --pink: #fd87fc;
  --blue: #82e6e6;
  --yellow: #ffc524;
  --green: #67d89c;
  --orange: #ff8e4f;
}
