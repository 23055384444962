.code-block {
    font-size  : calc(var(--small)*0.8);
    line-height: 1.3;

    pre {
        padding-block: 1em;
        overflow-x   : scroll;

        .token-line {
            padding-inline: 1em;
            border        : 1px solid rgb(45, 42, 85);


            &:hover {
                border: 1px solid rgba(165, 255, 144, 0.2);
            }
        }
    }
}