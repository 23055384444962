@use '../abstracts/mixins' as *;

.article-page {
  h1.page-title {
    font-family: var(--heading);
  }

  .title_container {
    grid-template-rows: fit-content fit-content;

    .subtitle {
      grid-area: 1/1/2/13;
      grid-row: 2;
      padding-inline: var(--grid-column);
      text-align: center;
      font-family: var(--body-bold);
      font-size: var(--heading-s);
      line-height: 1.3;
      margin-block-start: clamp(2.188rem, 1.847rem + 1.7vw, 3.125rem);
    }
  }

  &_content_container {
    .article-page_copy {
      padding-inline: var(--screen-padding);
    }

    @include mq(medium) {
      @include grid-12;

      .article-page_copy {
        grid-area: var(--two-thirds-left);
      }
    }

    h2 {
      display: inline-block;
      margin-bottom: 0.25em;
      margin-top: 0; //margin-top: clamp(3.75rem, 2.87rem + 3.76vw, 6.25rem); // 60 - 100px (addiert sich mit margin bottom zu 120 bzw 190 px
    }

    .primary-image {
      margin-bottom: clamp(1.875rem, 1.545rem + 1.41vw, 2.813rem);
    }

    p {
      //margin-bottom: clamp(3.75rem, 3.09rem + 2.82vw, 5.625rem); // 60 - 90px
      margin-bottom: clamp(1.875rem, 1.545rem + 1.41vw, 2.813rem); // 30-45
      word-wrap: break-all;
      hyphens: auto;

      @include mq(small) {
        max-width: 90%;
      }

      a {
        color: var(--link-color);

        &:hover,
        &:focus {
          text-decoration: none;
        }
      }
    }

    ul,
    ol {
      margin-block: 2em;
      background-color: var(--grey);
      font-family: var(--list);
      font-size: 1.2em;
      padding: 3.5rem;

      li {
        margin-bottom: 1em;
        list-style-type: '– ';
        line-height: 1.5;
        color: var(--steady);
      }
    }
  }
}
