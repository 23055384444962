@use '../abstracts/mixins' as *;
@use '../utilities/container';

.streamfield {
  @extend .container !optional;

  position: relative;
  z-index: 0;

  & & {
    padding: 0;
    width: min(100% - 2rem, 1200px);

    @include mq(big) {
      width: 100%;
    }

    @media screen and (max-width: 40rem) {
      margin-inline: 0;
      width: 100%;
    }
  }

  & &:first-child {
    margin-block-start: 0;
  }

  & &:last-child {
    margin-block-end: 0;
  }

  &.pink,
  & .pink {
    --color-background: var(--pink);
  }

  &.blue,
  & .blue {
    --color-background: var(--blue);
  }

  &.yellow,
  & .yellow {
    --color-background: var(--yellow);
  }

  &.green,
  & .green {
    --color-background: var(--green);
  }

  &.orange,
  & .orange {
    --color-background: var(--orange);
  }
}

@include mq(medium) {
  .streamfield {
    .container {
      width: min(100%, 1200px);
    }
  }
}
