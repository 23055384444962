@use '../../abstracts/mixins' as *;

.article_teaser {
  padding-top: 0;
  padding-bottom: 0;
  --teaser-title-size: var(--heading-m);

  .teaser--1 & {
    --teaser-title-size: var(--heading-l);
  }

  .content_wrapper {
    display: flex;
    flex-direction: column-reverse;
  }

  h2 {
    width: fit-content;
    font-family: var(--heading);
    font-size: var(--teaser-title-size);
    padding-block-end: clamp(1.25rem, -2vw + 3rem, 2.5rem); // 20 - 40px
  }

  .teaser_text {
    padding-block: clamp(2.5rem, 1.429rem + 5.36vw, 6.25rem); // 40 - 100
    padding-inline: calc(clamp(2.5rem, 1.429rem + 5.36vw, 6.25rem) / 2);
  }

  div.flow {
    margin-block-end: var(--base);
  }

  .readmore {
    &_container {
      display: flex;
      justify-content: flex-start;
    }

    &_link {
      display: inline-block;
      width: fit-content;
      margin-inline-end: var(--screen-padding);
    }
  }

  a {
    text-decoration: none;

    .readmore_link,
    h2 {
      display: block;
      position: relative;
      padding: 0.2em 0;
      overflow: hidden;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0.1em;
        background-color: var(--foreground-color);
        opacity: 1;
        transition: opacity 400ms, transform 400ms;
      }
    }

    h2::after {
      transform: translate3d(-101%, 0, 0);
    }

    .readmore_link::after {
      transform: translate3d(101%, 0, 0);
    }

    &:hover,
    &:focus {
      .readmore_link:after,
      h2:after {
        transform: translate3d(0, 0, 0);
      }
    }
  }

  @include mq(small) {
    .content_wrapper {
      @include grid-12;
      gap: 0;
    }

    .article_image {
      display: grid;
      place-content: center;
    }

    .teaser- {
      &-1 {
        grid-area: 1 / 2 / 2 / 12;
        --teaser-title-size: var(--heading-l);
      }

      &-1-2 {
        grid-area: 1 / 5 / 2 / 13;
        border-left: var(--streamfield-border);

        & + .article_image {
          grid-area: 1 / 1 / 2 / 5;
        }
      }

      &-2-1 {
        grid-area: 1 / 1 / 2 / 9;
        border-right: var(--streamfield-border);

        & + .article_image {
          grid-area: 1 / 9 / 2 / 13;
        }
      }

      &-1-1 {
        grid-area: 1 / 1 / 2 / 7;
        border-right: var(--streamfield-border);

        & + .article_image {
          grid-area: 1 / 7 / 2 / 13;
        }
      }
    }
  }
}
