.streamfield.buttons {
  display         : flex;
  flex-wrap       : wrap;
  justify-content : flex-start;
  gap             : 2.5rem 3.5rem;
  margin-block-end: clamp(2.5rem, 2.28rem + 0.94vw, 3.125rem);

  .button {
    text-decoration : none;
    font-family     : var(--aside);
    font-size       : var(--base);
    background-color: var(--foreground-color);
    color           : var(--background-color);
    border-radius   : 5px;

    &:hover {
      /* offset-x | offset-y | blur-radius | spread-radius | color */
      box-shadow: 0px 8px 35px 0 rgba(0, 0, 0, 0.30);
    }

    &--primary {
      color           : var(--foreground-color);
      background-color: var(--background-color);
      border          : 2px solid var(--foreground-color);

      &:hover {
        /* offset-x | offset-y | blur-radius | spread-radius | color */
        box-shadow: 0px 8px 20px 0 rgba(0, 0, 0, 0.15);
      }
    }


  }
}