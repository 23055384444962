@use '../abstracts/mixins' as *;

:where(*) {
  // this is a bad idea, but works for now
  font-variation-settings: 'wdth' var(--font-width, 105);
}

body {
  font-size: var(--base);
  font-family: var(--body);
  color: var(--foreground-color);
  line-height: 1.85;
  //letter-spacing: -0.5px;
}

h1,
h2,
h3,
h4 {
  --font-weight: 700;
  font-weight: normal;
  font-stretch: 100%;
  line-height: 1.1;
  color: var(--clr-neutral-800);
}

h1 {
  --font-weight: 400;
  font-family: var(--heading);
  color: var(--clr, var(--clr-primary-400));

  .article-listing-page & {
    font-family: var(--aside-bold);
    text-transform: lowercase;
    padding-inline: var(--screen-padding);
    grid-area: 1 / 1 / 2 / 10;

    @include mq(small) {
      padding-inline: 0;
    }
  }

  .home-page & {
    text-transform: lowercase;
    padding-inline: var(--screen-padding);
    grid-area: 1 / 1 / 2 / 10;

    @include mq(small) {
      padding-inline: 0;
    }
  }

  .article-page & {
    text-align: center;
    margin-block: 0;

    @include mq(small) {
      grid-area: 1 / 1 / 2 / 13;
      padding-inline: var(--grid-column);
    }
  }
}

// Font Variant for non-h1 Page Title
.page-title {
  font-family: var(--pagetitle);
  --font-width: 130;
}

// This is setting the font-sizes
// based on the ones in the abstract
// folder, go there to make updates

h1,
.h1 {
  font-size: var(--heading-xl);
}

h2,
.h2 {
  --flow-spacer: 1.5em;
  font-size: var(--heading-l);

  .article-page & {
    font-size: var(--heading-s);
    font-family: var(--body-bold);
  }
}

h3,
.h3 {
  --flow-spacer: 1.5em;
  font-size: var(--fs-600);
}

h4,
.h4 {
  font-size: var(--fs-500);
}

small,
.text-small {
  font-size: var(--small);
}

strong {
  --font-weight: 500;
  font-weight: normal;
}

a {
  color: var(--clr-primary-400);

  &:hover,
  &:focus {
    color: var(--clr-primary-500);
    text-decoration: none;
  }
}

p {
  margin-bottom: var(--column);
}

// p:not([class]),
// ul:not([class]),
// li:not([class]) {
//   max-width: 60ch;
// }
