.download {
  background-color: var(--color-background);
  width: 100%;
  max-width: 27rem;
  border: 2px solid black;
  border-radius: 10px;
  margin-inline: auto;
  line-height: 1.2;
  color: black;
  height: min-content;
  margin: 0;

  &__svg {
    margin-block-end: 1em;
  }

  &__title {
    font-family: var(--aside-bold);
    margin-block-end: 0.5em;
  }

  &__text {
    font-family: var(--aside);
    font-size: var(--small);
    margin-block-start: 0;
  }

  a {
    text-decoration: none;
    padding: 1.2em;
    display: block;

    .download__text,
    .download__title {
      display: block;
      position: relative;
      padding: 0.2em 0;
      overflow: hidden;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0.1em;
        background-color: var(--foreground-color);
        opacity: 1;
        transition: opacity 400ms, transform 400ms;
      }
    }

    .download__title::after {
      transform: translate3d(-101%, 0, 0);
    }

    .download__text::after {
      transform: translate3d(100%, 0, 0);
    }

    &:hover,
    &:focus {
      .download__text:after,
      .download__title:after {
        transform: translate3d(0, 0, 0);
      }
    }
  }
}
