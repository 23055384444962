@use '../abstracts/mixins'as *;

.article-listing-page {
    padding-inline: var(--screen-padding);

    .page-title {
        font-family  : var(--ci);
        font-size    : var(--heading-xl);
        margin-bottom: 0.5em;
        hyphens      : auto;
        text-wrap    : balance;
    }

    .listing-meta {
        font-family: var(--ci);
    }
}