@use 'breakpoints' as *;

@mixin mq($key, $type: min) {
  $size: map-get($breakpoints, $key);

  @media only screen and (#{$type}-width: $size) {
    @content;
  }
}

@mixin grid-12 {
  max-width: var(--scale-end);
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 0px;
}
