@use '../abstracts/mixins' as *;

.article-page {
  .tags {
    font-family: var(--aside);

    margin: 0 var(--screen-padding) 2.5rem;
    padding-top: var(--screen-padding);
    border-top: 2px solid black;

    @include mq(medium) {
      margin: 0 0 var(--base);
      padding: 0;
      border: none;
    }

    &__heading {
      margin-right: 1ch;
    }

    &__listing {
      text-transform: lowercase;
      margin: 0;
      display: inline;

      .tag {
        word-break: keep-all;
        text-decoration: none;

        &:focus,
        &:hover {
          text-decoration: underline;
        }

        &:not(:last-child):after {
          content: ' · ';
          display: inline-block;
          text-decoration: none;
          padding-inline: 1ch;
        }
      }
    }
  }
}
