@use "../../utilities/container";

.streamfield {
  @extend .container !optional;
  box-sizing: border-box;

  .home-page & {
    --streamfield-border: 2px solid var(--foreground-color);

    border: var(--streamfield-border);
    border-bottom: none;

    &:last-of-type {
      border-bottom: var(--streamfield-border);
    }
  }
}
