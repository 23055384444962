@use '../abstracts/mixins' as *;

.subheader {
  .title_container {
    p,
    h1 {
      font-family: var(--aside-bold);
      text-transform: lowercase;
      font-size: var(--heading-xl);
      padding-inline: var(--screen-padding);
      grid-area: 1 / 2 / 2 / 11;
      line-height: 1.2;

      @include mq(small) {
        padding-inline: 0;
        max-height: 4em;
      }
    }
  }
}
