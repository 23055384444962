@use '../abstracts/mixins' as *;

.nav {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 1;
  overflow: hidden;
  transition: height 0.2s ease;

  &--open {
    height: 100vh;
    opacity: 0.95;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  }

  &__list {
    &-item {
    }
  }

  &__sub-menu {
  }

  &__link {
    &:hover,
    &.active {
    }
  }
}
