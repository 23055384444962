// Declare font here
// @font-face {
//   font-family: "Alaska";
//   src: url("../fonts/alaska.ttf") format("truetype");
//   font-display: swap;
// }

/* pt-serif-regular - latin-ext_latin */
@font-face {
  font-family: 'PT Serif';
  font-style: normal;
  font-weight: 400;
  src: local(''), url('../assets/fonts/pt-serif-v17-latin-ext_latin-regular.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('../assets/fonts/pt-serif-v17-latin-ext_latin-regular.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}

/* pt-serif-italic - latin_latin-ext */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'PT Serif Italic';
  font-style: italic;
  font-weight: 400;
  src: local(''), url('../assets/fonts/pt-serif-v18-latin_latin-ext-italic.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* pt-serif-700 - latin-ext_latin */
@font-face {
  font-family: 'PT Serif Bold';
  font-style: normal;
  font-weight: 700;
  src: local(''), url('../assets/fonts/pt-serif-v17-latin-ext_latin-700.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('../assets/fonts/pt-serif-v17-latin-ext_latin-700.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}

/* pt-serif-700italic - latin-ext */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'PT Serif Bold Italic';
  font-style: italic;
  font-weight: 700;
  src: local(''), url('../assets/fonts/pt-serif-v17-latin-ext-700italic.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url('../assets/fonts/pt-serif-v17-latin-ext-700italic.woff') format('woff');
  /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* abril-fatface-regular - latin-ext */
@font-face {
  font-family: 'Abril Fatface';
  font-style: normal;
  font-weight: 400;
  src: local(''), url('../assets/fonts/abril-fatface-v19-latin-ext-regular.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('../assets/fonts/abril-fatface-v19-latin-ext-regular.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}

/* @font-face {
  font-family: 'DIN Pro Regular';
  font-style: normal;
  font-weight: 400;
  src: local('DIN Pro'), url('../assets/fonts/DINPro.woff2') format('woff2'),
    url('../assets/fonts/DINPro.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'DIN Pro Bold';
  font-style: normal;
  font-weight: 400;
  src: local('DIN Pro'), url('../assets/fonts/DINPro-Bold.woff2') format('woff2'),
    url('../assets/fonts/DINPro-Bold.woff') format('woff');
  font-display: swap;
} */

@font-face {
  font-family: 'Neue DIN Semi-Wide';
  src: local(''), url('../assets/fonts/NeueDINVAR.woff2') format('woff2-variations');
  font-weight: 300;
  font-stretch: 75% 125%;
  font-style: oblique 0deg 20deg;
}

@font-face {
  font-family: 'Neue DIN Semi-Wide Bold';
  src: local(''), url('../assets/fonts/NeueDINVAR.woff2') format('woff2-variations');
  font-weight: 600;
  font-stretch: 75% 125%;
  font-style: oblique 0deg 20deg;
}

@font-face {
  font-family: 'Neue DIN Semi-Wide Heading';
  src: local(''), url('../assets/fonts/NeueDINVAR.woff2') format('woff2-variations');
  font-weight: 750;
  font-stretch: 75% 125%;
  font-style: oblique 0deg 20deg;
}
