.source {
    &__wrapper {
        font-size           : var(--small);
        font-family         : var(--aside);
        padding-inline-start: 2rem;

        a {
            text-decoration: none;
            display        : block;

            &:focus,
            &:hover {
                .source__title {
                    text-decoration: underline;
                }
            }

        }

    }


    &__author {
        font-family: var(--aside-bold);
    }

    &__title {
        display            : inline-block;
        margin-inline-start: 0.5em;
    }



}