@use '../abstracts/mixins'as *;

.article-meta-data {
    display    : inline-block;
    font-family: var(--aside);
    width      : 100%;
    margin     : clamp(2.188rem, 1.847rem + 1.7vw, 3.125rem) 0 clamp(4.688rem, 3.551rem + 5.68vw, 7.813rem);

    p {
        margin: 0 auto;
        width : fit-content;

        .duration {
            font-family: var(--aside-bold);
        }
    }
}