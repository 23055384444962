.tags-container {
  .button-container {
    display: grid;
    place-content: center;

    button {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: var(--aside-bold);
      font-size: var(--small);
      color: var(--foreground-color);
      transition: all 0.2s ease-in;

      span.icon {
        max-height: 3.125rem;
      }

      svg {
        height: auto;
        width: 2.2em;
        fill: var(--foreground-color);

        &.showMore {
          margin-bottom: 0.5em;
          transition: all 0.2s ease-in;
        }

        &.showLess {
          margin-top: 0.5em;
          transition: all 0.2s ease-in;
        }
      }

      &:hover {
        box-shadow: none;
        letter-spacing: 0.1em;

        svg {
          &.showMore {
            margin-top: 0.5em;
            margin-bottom: 0;
          }

          &.showLess {
            margin-top: 0;
            margin-bottom: 0.5em;
          }
        }
      }
    }
  }

  .tags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: clamp(0.938rem, 0.827rem + 0.47vw, 1.25rem); // 15 - 20
    margin-block-end: calc(clamp(0.938rem, 0.827rem + 0.47vw, 1.25rem) * 2); // 15 - 20;

    .tag {
      line-height: 1.2;
      box-sizing: border-box;
      background-color: var(--foreground-color);
      border-radius: 30px;
      color: var(--background-color);
      font-family: var(--aside);
      font-size: var(--small);
      text-transform: lowercase;
      padding: 10px 20px;
      border: 2px solid var(--foreground-color);
      text-decoration: none;
      transition: all 0.2s ease-in;

      &:hover,
      &:focus {
        background-color: var(--background-color);
        color: var(--foreground-color);
      }
    }
  }
}
