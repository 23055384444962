@use '../../abstracts/mixins' as *;

.big-quote {
  font-size: var(--content-xl);
  font-family: var(--heading);
  line-height: 1.25;
  background-color: var(--color-background);
  padding-block: clamp(5rem, 4.286rem + 3.57vw, 7.5rem); // 80 - 120 px
  padding-inline: var(--screen-padding);

  @include mq(small) {
    padding-inline: 0;
  }

  .dark-theme & {
    color: var(--background-color);
  }

  .content_wrapper {
    @include mq(small) {
      @include grid-12;

      .quote {
        grid-area: 1 / 2 / 2 / 13;
      }
    }
  }

  .quote-author {
    margin-top: 0;
  }
}
