body {
  background-color: var(--background-color);
}

.dark-theme {

  --background-color: var(--dm-backdrop);
  --foreground-color: white;

}

.base-page {
  max-width: 1440px;
  margin   : 0 auto;
}

.copy_type {
  font-family: var(--body);
  font-size  : var(--base);
  line-height: 1.52;
}