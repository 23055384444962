.article-page {
  .highlight-quote {
    padding-left: 12.5%;
    margin-block: clamp(1.875rem, 1.545rem + 1.41vw, 2.813rem);

    .highlight {
      &__text p {
        font-family: var(--heading);
        font-size: var(--highlight-quote);
        line-height: 1.3;
        word-break: keep-all;
        hyphens: none;
        margin: 0;
        max-width: none;
      }

      &__author {
        font-family: var(--aside-bold);
        font-size: var(--heading-s);
        text-align: right;
        margin-inline-end: 3em;
      }
    }
  }
}
