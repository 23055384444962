footer {
  display: grid;
  place-content: center;
  background-color: var(--text);
  color: white;
  font-family: var(--ci);
  padding-top: clamp(2.5rem, 1.136rem + 6.82vw, 6.25rem);
  padding-bottom: clamp(1.25rem, 0.795rem + 2.27vw, 2.5rem);

  .footer__menu {
    list-style-type: none;
    padding-left: 0;
    text-align: center;

    &__item {
      text-decoration: none;
      line-height: 1.75;
      transition: all 0.2s ease-in;
      display: inline-block;
      position: relative;
      padding: 0.2em 0;
      overflow: hidden;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0.1em;
        background-color: white;
        opacity: 1;
        transform: translate3d(-102%, 0, 0);
        transition: transform 300ms;
      }

      &:hover::after,
      &:focus::after {
        transform: translate3d(0, 0, 0);
      }
    }
  }

  .copyright {
    font-size: var(--fs-300);
  }
}
