@use '../abstracts/mixins' as *;

.article-information {
  font-family: var(--aside);
  line-height: 1.3;
  margin-bottom: clamp(1.875rem, 1.545rem + 1.41vw, 2.813rem);
  margin-inline: var(--screen-padding);

  @include mq(medium) {
    grid-area: var(--last-third);
    align-self: start;
    position: sticky;
    top: 2rem;
  }

  &__heading {
    font-family: var(--aside-bold);
  }

  .wrapper {
    @include mq(small) {
      margin-bottom: 3.125rem;
    }
  }

  .author {
    display: flex;
    margin-bottom: var(--base);
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: clamp(1.25rem, 1.14rem + 0.47vw, 1.563rem); // 20 - 25 px

    @include mq(medium) {
      justify-content: flex-start;
    }

    img {
      max-width: 98px;
      max-height: 98px;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  .social-media {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__heading {
      margin-bottom: 1rem;
    }

    @include mq(medium) {
      align-items: flex-start;
    }

    &-icons {
      display: flex;
      gap: 1rem;

      svg {
        width: 44px;
        aspect-ratio: 1/1;

        circle {
          fill: var(--foreground-color);
        }

        path,
        circle.Instagram_dark_svg__cls-2 {
          fill: var(--background-color);
        }
      }
    }
  }
}
