@use '../abstracts/mixins'as *;

.container {
  // width: min(100% - 2rem, 65rem);
  margin: 0 auto;

  &.title_container {
    @include mq(small) {
      @include grid-12;
      padding: 0;
    }

  }
}

@include mq(big) {
  .container {
    --spacer: 5rem;
    padding : 0 9.375rem;
  }
}