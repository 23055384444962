@use '../../abstracts/mixins'as *;

.downloads__container {
    display              : grid;
    flex-direction       : column;
    grid-template-columns: 1fr;
    grid-template-rows   : auto;
    gap                  : 1.25rem;
    margin-block-end     : clamp(1.875rem, 1.545rem + 1.41vw, 2.813rem);
    justify-content      : stretch;
    margin-inline        : auto;
    justify-items        : center;

    @include mq(small) {
        grid-template-columns: 1fr 1fr;
        justify-items        : stretch;
    }

}